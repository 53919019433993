import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom'
 

const Hero = () => {
    return (
        <div className='hero' id='hero-navigate'>
            <div className='hero-text'>
                <h1 className='hero-h1'>Welcome to The Nail Lab</h1>
                <div className='hero-para-div'>
                    <p className='hero-para'>
                        Your Sanctuary for Exquisite Nail Care and Wellness.
                        We are dedicated to providing you with top-notch 
                        nail care and a relaxing and luxurious experience.
                    </p>
                </div>
                <Link to='/appointment'>
                    <button className='hero-btn'>Book Now</button>
                </Link>
            </div>
        </div>
    )
}

export default Hero
