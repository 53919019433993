import React from 'react'
import massage from '../../../assets/massage.jpg'
import nail_care from '../../../assets/nail_care.jpg'
import logo from '../../../assets/logo.png'
// import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";



import './Services.css'

const Services = () => {
    return (
        <div className='services' id='what-we-do'>

            <div className='services-container'>

                <div className='services-info'>
                    <img src={logo} alt=''></img>
                    <h1>Indulge in Timeless Elegance</h1>
                    <p className='mr-bottom services-para'>Whether you're looking for a classic manicure, a trendy nail design, or a rejuvenating pedicure, we've got you covered.</p>
                    <p className='mr-bottom services-para'>We use high-quality products and follow strict hygiene standards to ensure that your safety and satisfaction are our top priorities.</p>
                </div>

                <div className='services-grid'>
                    <div className='services-box'>
                        <img src={nail_care} alt=''></img>
                        <h2>Nail Care</h2>
                    <p className='mr-bottom services-para'>Our team of skilled technicians is passionate about</p>
                    <p className='mr-bottom services-para'>creating beautiful and healthy nails.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
