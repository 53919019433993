import React from 'react'
import './Book.css'

function myFunction() {
    var x = document.getElementById("myFrame").src;
    document.getElementById("demo").innerHTML = x;
}
const Book = ({info}) => {

    return (
        <div className='quick-book'>
		<iframe id="myFrame" src="https://square.site/appointments/buyer/widget/v9t87oe41a7o9m/LRXTVVB9ZHYR3.js"></iframe>
		<p>Click the button to get the URL of the iframe.</p>
		<button onclick="myFunction()">Try it</button>
		<p id="demo"></p>
           <span>{info}</span>
        </div>
    )
}

export default Book
